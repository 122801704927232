import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthComponent } from './auth.component';
import { NbAlertModule, NbInputModule, NbButtonModule, NbIconModule, NbCardModule, NbLayoutModule, NbSelectModule } from '@nebular/theme';
import { ThemeModule } from '../../@theme/theme.module';
import { SigninComponent } from './signin/signin.component';
import { FormsModule } from '../../pages/forms/forms.module';
import { RouterModule } from '@angular/router';
import { NbAuthModule } from '@nebular/auth';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { LogoutComponent } from './logout/logout.component';
import { CheckUidComponent } from './check-uid/check-uid.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ThemeModule,
    RouterModule,
    NbAlertModule,
    NbInputModule,
    NbButtonModule,
    NbAuthModule,
    NbIconModule,
    NbCardModule,
    NbLayoutModule, NbSelectModule
  ],
  declarations: [
    AuthComponent,
    SigninComponent,
    LogoutComponent,
    CheckUidComponent,
  ],
})
export class AuthModule { }
